<template>
  <section class="bg-gray-100">
    <div
      class="p-4 py-8 max-w-xl mx-auto container mb-20"
      v-if="period.id !== undefined"
    >
      <p class="tracking-wider uppercase text-xl md:text-3xl mb-4">
        PERIODE PENDAFTARAN {{ period.name }}
      </p>
      <div class="grid grid-cols-2 gap-x-2">
        <p class="bg-green-700 text-white p-4 shadow">
          <span class="block tracking-widest">MULAI</span>
          <span class="text-xl font-bold">
            {{ period.started_at | indonesian_date_format }}
          </span>
        </p>
        <p class="bg-red-500 text-white p-4 shadow">
          <span class="block tracking-widest">SELESAI</span>
          <span class="text-xl font-bold">
            {{ period.ended_at | indonesian_date_format }}
          </span>
        </p>
      </div>

      <!-- edit information form gate -->
      <form
        @submit.prevent="triggerSubmit()"
        v-if="isInPeriodRange"
        class="mt-10 px-4 py-6 bg-white shadow-lg rounded-lg"
      >
        <label for="code" class="block">Kode Peserta Didik *</label>
        <input
          id="code"
          type="password"
          class="p-4 border-b border-gray-300 w-full"
          placeholder="9248xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          v-model="form.student_code"
        />

        <label class="block mt-6" for="contact">Kode Pendaftaran *</label>
        <input
          type="password"
          id="contact"
          placeholder="9248xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          class="p-4 border-b border-gray-300 w-full"
          v-model="form.test_code"
        />

        <button
          type="submit"
          class="w-full px-6 py-4 bg-green-500 text-white font-bold mt-4"
        >
          <span>Mulai Mengisi Formulir</span>
        </button>

        <a
          target="_blank"
          rel="noopener noreferrer"
          :href="`https://wa.me/${whatsapp
            .replace(/^0/, '62')
            .replace(/[\W_]/g, '')}`"
          class="text-red-700 block py-2"
        >
          <span class="block"
            >*) Belum punya kode calon peserta didik atau pendaftaran? Mohon
            hubungi staf kami.</span
          >
          <span
            class="border border-green-500 text-green-500 bg-white rounded mt-2 p-2 px-4 inline-block"
          >
            <fa-icon :icon="['fab', 'whatsapp']"></fa-icon>
            <span class="ml-2">{{ whatsapp }}</span>
          </span>
        </a>
      </form>
      <p
        v-else
        class="p-4 py-8 mt-2 mb-20 text-center uppercase tracking-wider bg-white shadow"
      >
        <span> pendaftaran akan dibuka pada </span>
        <span class="block font-bold text-xl mt-1">
          {{ period.started_at | indonesian_datetime_format }}
        </span>
      </p>
    </div>

    <p v-else class="p-4 py-8 mb-20 text-center uppercase tracking-wider">
      periode pendaftaran belum diumumkan
    </p>
  </section>
</template>

<script>
import _clone from "lodash/clone";

export default {
  data() {
    return {
      form: {
        student_code: null,
        test_code: null,
      },
      formDefault: {},
    };
  },
  props: {
    period: {
      type: Object,
      required: true,
    },
    whatsapp: {
      type: String,
      required: true,
    },
  },
  methods: {
    triggerSubmit() {
      this.$emit("submitted", this.form);
      this.form = _clone(this.formDefault);
    },
  },
  computed: {
    isInPeriodRange() {
      return new Date() >= new Date(this.period.started_at);
    },
  },
  mounted() {
    this.formDefault = _clone(this.form);
  },
};
</script>